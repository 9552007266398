import {Box, Card, Form, FormField} from "grommet";
import CustomAppBar from "./AppBar";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React from "react";
import { useCookies } from 'react-cookie';
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";


function Login(props){

    const [formData,setFormData] = React.useState({username:"",password:""});
    const [showPassword,setShowPassword] = React.useState(false);
    const [
        //cookies,
        , setCookie] = useCookies();

    function login() {
        const url = window.location.hostname!=="localhost"? window.location + "api/login/" : "http://elif.localhost:8000/api/login/"
      
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(formData),
          })
          .then(response => response.json())
          .then(result => {
            const token=result.access
            const refreshToken=result.refresh
              if (token) {
                  setCookie('accessToken',
                           token,
                            {
                                path: '/',
                                maxAge: process.env.REACT_APP_EXPIRATION_SECONDS
                            });
                  setCookie('refreshToken',
                          refreshToken,
                            {
                                path: '/',
                                maxAge: process.env.REACT_APP_REFRESH_EXPIRATION_SECONDS
                            });
                  sessionStorage.setItem("accessToken", token)
                  props.setLogin(false)
              } else {
                  alert("Email o password errati");
              }
          })
          .catch(error => {
            alert("Errore durante la login");
            console.error('Si è verificato un errore:', error);
          });
      }

    return(
        <Box margin="small" align="center" gap="medium">
            <CustomAppBar title={"Login"}/>
            <Box align="center" gap="small" margin={"xlarge"}>
                <Card width="500px" pad="small" gap="medium" margin="medium">
                    <Form
                          value={formData}
                          onSubmit={({ value }) => {login()}}
                        >
                        <FormField plain name="Email" htmlFor="text-input-id" >
                            <TextField id="outlined-basic" label="Username" variant="outlined" value={formData?.username || ""} onChange={(e)=>setFormData({...formData,"username":e.target.value})} />
                        </FormField>
                        <FormField name="Password" htmlFor="text-input-id" >
                             <TextField
                              id="outlined-basic"
                              label="Password"
                              variant="outlined"
                              type={showPassword ? 'text' : 'password'}
                              value={formData.password}
                              onChange={(e)=>{setFormData({...formData,"password":e.target.value})}}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={(e)=>{setShowPassword(!showPassword )}}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                        </FormField>

                    </Form>
                    <Button variant="contained" onClick={login}>Login</Button>
                </Card>
            </Box>
        </Box>
    )
}

export default Login;