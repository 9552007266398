
import { Box } from "grommet";
import CustomAppBar from "./AppBar";
import {DataGrid} from "@mui/x-data-grid";
import React from "react";
import {Button as ButtonMui} from "@mui/material";
import LayerVisualizza from "./LayerVisualizza";
import Chip from '@mui/material/Chip';

function Home(){

    const [columns,setColumns] = React.useState([]);
    const [dynamicFormsData,setDynamicFormsData] = React.useState({});
    const [showLayerVisualizza,setShowLayerVisualizza] = React.useState(false);
    const [rawData,setRawData] = React.useState([]);
    const [rawSel,setRawSel] = React.useState({});
    const [tenantImage,setTenantImage] = React.useState(null);

    function getFormTenantConf() {
        const url = window.location.hostname!=="localhost"? window.location + "checkcontacts/get-form-config" : "http://elif.localhost:8000/checkcontacts/get-form-config"
      
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers:{
                "Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
            }
          })
          .then(response => response.json())
          .then(result => {
            setDynamicFormsData(result);
            let columnsDataGrid=[];
            for (let key in result) {
                columnsDataGrid.push({field:key,headerName:key,width:300})
            }
            columnsDataGrid.push({field:"read",headerName:"Email letta",width:200,
             renderCell: (cellValues) => {
                return(
                    cellValues?.row?.read ?
                    <Chip label="Visualizzata" color="success" variant="cotained" />
                    :
                    <Chip label="Non visualizzata" color="error" variant="cotained" />
                )
             }
            })
            columnsDataGrid.push({field:"read_date_time",headerName:"Orario lettura email",width:200})
            columnsDataGrid.push({field:"date_of_expire",headerName:"Data scadenza",width:200})
              columnsDataGrid.push({field:"status",headerName:"Stato",width:200})
            columnsDataGrid.push({field:"",headerName:"Opzioni",width:300, 
            renderCell: (cellValues) => {
                return (
                  <Box direction="row" gap="small">
                      <ButtonMui
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            setRawSel(cellValues.row);
                            setShowLayerVisualizza(true);
                          }}
                        >
                          Visualizza
                        </ButtonMui>
                    <ButtonMui
                      variant="contained"
                      color="error"
                      onClick={(event) => {
                        let res=window.confirm("Sei sicuro di voler eliminare questo elemento?");
                        if(res){
                            deleteCustomersContactsData(cellValues?.row?.id)
                        }
                      }}
                    >
                      Cancella
                    </ButtonMui>
                  </Box>
                );},
            })
            setColumns(columnsDataGrid);

          })
          .catch(error => {
            alert("Errore");
            console.error('Si è verificato un errore:', error);
          });
      }

        function getCustomersContactsData(){
            const url = window.location.hostname!=="localhost"? window.location + "checkcontacts/get-check-contacts-data" : "http://elif.localhost:8000/checkcontacts/get-check-contacts-data"
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers:{
                    "Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
                }
              })
              .then(response => response.json())
              .then(result => {
                setRawData(result);
              })
              .catch(error => {
                alert("Errore");
                console.error('Si è verificato un errore:', error);
              });
        }

        function deleteCustomersContactsData(id){
            const url = window.location.hostname!=="localhost"? window.location + "checkcontacts/delete-check-contacts-data" : "http://elif.localhost:8000/checkcontacts/delete-check-contacts-data"
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
                  },
                body: JSON.stringify({"id":id}),
              })
              .then(response => response.json())
              .then(result => {
                alert("Eliminato con successo");
                getCustomersContactsData();
              })
              .catch(error => {
                alert("Errore");
                console.error('Si è verificato un errore:', error);
              });
        }

           function getTenantImage(){
            const url = window.location.hostname!=="localhost"? window.location + "checkcontacts/get-tenant-image" : "http://elif.localhost:8000/checkcontacts/get-tenant-image"
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers:{
                    "Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
                }
              })
              .then(response => response.json())
              .then(result => {
                if(result){
                    setTenantImage(window.location + result?.Path)
                }
              })
              .catch(error => {
                console.error('Si è verificato un errore:', error);
              });
        }

      React.useEffect(()=>{
        getFormTenantConf();
        getTenantImage();
      },[])

      React.useEffect(()=>{
        getCustomersContactsData();
      },[columns])

    return(
        <Box margin="small" align="center" gap="medium">
            <CustomAppBar title={"Home"} reload={getCustomersContactsData} image={tenantImage} />
                <Box style={{marginTop:"30px"}}>
                    <div style={{ height: '75vh', width: '98vw' }}>
                        <DataGrid
                            rows={rawData}
                            columns={columns}
                        />
                    </div>
                </Box>
                {
                    showLayerVisualizza && <LayerVisualizza rawSel={rawSel} formDynamicData={dynamicFormsData} onEscLayer={()=>{setShowLayerVisualizza(false);getCustomersContactsData()}}/>
                }
           
        </Box>
    )
}

export default Home;