import { Layer, Box ,Text, Select} from "grommet";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import {Button as ButtonMui, MenuItem} from "@mui/material";
import React from "react";

function LayerVisualizza(props) {

        const [status,setStatus] = React.useState(props?.rawSel["status"] || null)
      function changeCustomerDataStatus(option){
            setStatus(option);
            const url = window.location.hostname!=="localhost"? window.location + "checkcontacts/change-customer-data-status" : "http://elif.localhost:8000/checkcontacts/change-customer-data-status"
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
                body: JSON.stringify({"id":props?.rawSel["id"],"status":option}),
              })
              .then(response => response.json())
              .then(result => {
                console.log("RES",result);
              })
              .catch(error => {
                alert("Errore");
                console.error('Si è verificato un errore:', error);
              });
        }

    function widgetFromByType(key, index) {
        const objType = props?.formDynamicData[key];
        if (objType === "text") {
            return (
                <div>
                    <TextField multiline maxRows={10} fullWidth  key={key} id={"filled-multiline-flexible" + key} label={key} variant="outlined" value={props?.rawSel[key] || ""} />
                </div>
            )
        }
    }


    return (
        <Layer onEsc={props?.onEscLayer} onClickOutside={props?.onEscLayer}> 
            <Box margin="medium" gap="small" width="500px">
                <Text>DATI RICEVUTI</Text>
                {
                    Object.keys(props?.formDynamicData).map((key, index) => (
                        widgetFromByType(key, index)
                    ))
                }
                <Divider>Modifica Stato</Divider>
                <Select
                  options={['Pending', 'Presa in carico', 'Stipulazione contratto','Conclusa']}
                  value={status}
                  onChange={({ option }) => changeCustomerDataStatus(option)}
                />

            </Box>

        </Layer>
    )
}

export default LayerVisualizza;