import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CachedIcon from '@mui/icons-material/Cached';

export default function CustomAppBar(props) {

  return (
    <AppBar component="nav">
    <Toolbar>
      {
          props?.image &&
          <img style={{backgroundColor: "white", height: "50px", marginRight: "30px",marginTop:"5px",marginBottom:"5px"}} src={props?.image}/>

      }
      <Typography
          variant="h6"
          component="div"
          sx={{flexGrow: 1, display: {xs: 'none', sm: 'block' } }}
      >
        {props.title}
      </Typography>
      {
        props.reload &&  
        <IconButton aria-label="reload" size="large" onClick={()=>props?.reload()}>
            <CachedIcon fontSize="inherit" />
        </IconButton>
      }
       
    </Toolbar>
  </AppBar>
  );
}