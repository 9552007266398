import logo from './logo.svg';
import './App.css';
import React ,{useState}from "react";
import { Box , TextInput,Button,Text,DateInput} from 'grommet';
//import TextField from '@mui/material/TextField';
import CustomAppBar from './AppBar.js';
import Login from './Login.js';
import Home from './Home.js';

function App() {

  const [login,setLogin] = useState(true);

  React.useEffect(()=>{
    if(!sessionStorage.getItem("sessionToken")){
      setLogin(true);
    }else{
      setLogin(false);
    }
  },[])


  return (
   <Box margin="small" align="center" gap="medium">
      <CustomAppBar title={"Home"}/>
      {
        login? <Login setLogin={setLogin}/> : <Home/>
      }

   </Box>
  );
}

export default App;
